import store from "@/store/index";
import router from "@/router/index";

export default (exception) => {
  let response = undefined;
  if (exception.response !== undefined) {
    response = exception.response;
  }

  if (!navigator.onLine) {
    throw "Sem acesso à internet, por favor, tente mais tarde.";
  }

  if (!response) {
    throw "Servidor indisponivel, tente mais tarde.";
  }

  if (response.status === 401) {
    store.dispatch("logout").then(() => {
      router.push({ path: "/entrar" });
    });
  }

  if (response.status === 422) {
    let errorRules = {};

    const erros = response.data.errors;
    const keys = Object.keys(erros);
    keys.forEach((item) => {
      errorRules[item] = erros[item][0];
    });

    throw errorRules;
  }

  if (response.data !== undefined && response.data.message !== undefined) {
    throw response.data.message;
  }

  throw "Ooops! Algo deu errado.";
};

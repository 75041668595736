<template>
  <div>
    <v-card width="100%" color="#fff">
      <v-img contain max-height="250px" src="/educaaws.jpeg"></v-img>
    </v-card>
    <v-container fluid>
      <div class="text-center">
        <v-row>
          <v-col>
            <v-card class="mx-auto my-12">
              <v-card-title>
                <div class="text-center" style="width: 100%;">
                  {{ data.name }}
                </div>
              </v-card-title>
              <v-card-text>
                <iframe
                  ref="youtubeIframe"
                  width="1024"
                  height="576"
                  :src="data.link"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import ComercialService from "@/services/Comercial/ComercialService";
import ToastService from "@/services/ToastService";
import instanceAxios from "../../plugins/axios";
export default {
  data: () => ({
    loading: false,
    data: {},
    ComercialService: new ComercialService(""),
  }),

  mounted: async function() {
    const id = this.$route.params.id;

    this.loading = true;
    await this.showData(id);

    const youtubeIframe = this.$refs.youtubeIframe;

    // Ouvinte de evento para o carregamento do iframe
    youtubeIframe.addEventListener("load", () => {
      const thumbnailOverlays = document.querySelectorAll(
        ".ytp-cued-thumbnail-overlay-image"
      );
      const backgroundImageURL = 'url("http://localhost:8080/educaaws.jpeg")';

      thumbnailOverlays.forEach((overlay) => {
        overlay.style.backgroundImage = backgroundImageURL;
      });
      // URL da imagem que você deseja usar como background

      console.log(youtubeIframe.style.backgroundImage);
      // Aplicar o estilo de background-image após o carregamento do iframe
      youtubeIframe.style.backgroundImage = backgroundImageURL;
    });
  },

  methods: {
    async showData(id) {
      try {
        this.data = (await instanceAxios.get("videos/" + id)).data;
      } catch (exception) {
        ToastService("Não foi possível consultar os dados.", "error");

        this.cancel();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style></style>

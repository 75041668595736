import api from "../plugins/axios";
import ResponseService from "./ResponseService";
import ExceptionService from "./ExceptionService";

export default class API {
  constructor(baseEndpoint) {
    this.api = api;
    this.baseEndpoint = baseEndpoint;
  }

  get = async (endpoint) => {
    try {
      let url = this.baseEndpoint;
      console.log(url)
      if (endpoint) {
        url = `${this.baseEndpoint}/${endpoint}`;
      }
      const { data } = await api.get(url);

      if (!data['data']) {
        var info = [];
        info['data'] = data;
        return await ResponseService(info);
      }

      return await ResponseService(data);
    } catch (exception) {
      throw ExceptionService(exception);
    }
  };

  getById = async (endpoint, id) => {
    try {
      const { data } = await api.get(`${this.baseEndpoint}/${endpoint}/${id}`);

      if (!data['data']) {
        var info = [];
        return await ResponseService(info['data'] = data);
      }

      return await ResponseService(data);
    } catch (exception) {
      throw ExceptionService(exception);
    }
  };

  post = async (endpoint, params) => {
    try {
      let url = this.baseEndpoint;
      if (endpoint) {
        url = `${this.baseEndpoint}/${endpoint}`;
      }

      console.log(url);

      const { data } = await api.post(url, params);

      if (!data['data']) {
        var info = [];
        info['data'] = data;
        return await ResponseService(info);
      }

      return await ResponseService(data);
    } catch (exception) {
      throw ExceptionService(exception);
    }
  };

  put = async (endpoint, params) => {
    try {
      const { data } = await api.put(
        `${this.baseEndpoint}/${endpoint}/${params.id}`,
        params
      );

      if (!data['data']) {
        var info = [];
        info['data'] = data;
        return await ResponseService(info);
      }

      return await ResponseService(data);
    } catch (exception) {
      throw ExceptionService(exception);
    }
  };

  delete = async (endpoint) => {
    try {
      const { data } = await api.delete(`${this.baseEndpoint}/${endpoint}`);

      if (!data['data']) {
        var info = [];
        return await ResponseService(info['data'] = data);
      }

      return await ResponseService(data);
    } catch (exception) {
      throw ExceptionService(exception);
    }
  };

  postOrPut = async (endpoint, params) => {
    const method = params.id ? `put` : `post`;
    try {
      let url = this.baseEndpoint;
      if (endpoint) {
        url = `${this.baseEndpoint}/${endpoint}`;
      }

      const data = await api[method](url, params);

      if (!data['data']) {
        var info = [];
        info['data'] = data;
        return await ResponseService(info);
      }

      return await ResponseService(data.data);
    } catch (exception) {
      console.log(exception)
      throw ExceptionService(exception);
    }
  };
}
